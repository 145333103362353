import { FeaturedVideoOverlay } from './FeaturedVideoOverlay';
import { VideoAssetOverlay } from './VideoAssetOverlay';

interface VideoImageOverlayProps {
  duration?: number;
  humanDuration?: string;
  published?: string;
  caption?: string;
  hideDuration?: boolean;
  hideCaption?: boolean;
  variant: 'featured' | 'video-asset';
}

export function VideoImageOverlay({
  duration,
  humanDuration,
  caption,
  hideDuration = false,
  hideCaption = false,
  published,
  variant,
}: VideoImageOverlayProps) {
  switch (variant) {
    case 'featured':
      return (
        <FeaturedVideoOverlay
          duration={duration}
          humanDuration={humanDuration}
          caption={caption}
          hideDuration={hideDuration}
          hideCaption={hideCaption}
        />
      );
    case 'video-asset':
      return (
        <VideoAssetOverlay
          duration={duration}
          published={published}
          humanDuration={humanDuration}
          caption={caption}
          hideDuration={hideDuration}
        />
      );
  }
}
