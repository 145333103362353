import type { SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { SocialButton } from 'components/buttons';
import { FacebookIcon, LinkIcon, ShareIcon, TwitterIcon } from 'components/icons';
import { TABLET } from 'styles/theme';

interface Props {
  path: string;
  className?: string;
}

export const SocialMediaShareIcons = ({ path, className }: Props) => {
  const [showCustomShare, setShowCustomShare] = useState(false);
  const [isNavigatorShare, setIsNavigatorShare] = useState(false);
  const [articleUrl, setArticleUrl] = useState<string | undefined>(undefined);

  const stopPropagation = (event: SyntheticEvent) => event.stopPropagation();

  useEffect(() => {
    const navigator = window.navigator as Navigator;
    if (navigator.share !== undefined && window.innerWidth < TABLET) {
      setIsNavigatorShare(true);
    }
  }, []);

  useEffect(() => {
    setArticleUrl(window.origin + path);
  }, [path]);

  if (!articleUrl) {
    return null;
  }

  const utmQsParams = new URLSearchParams({
    utm_source: 'tv4.se',
    utm_medium: 'shared_link',
  });

  const shareUrl = `${articleUrl}?${utmQsParams}`;
  const facebookShareUrl = `https://www.facebook.com/sharer.php?u=${shareUrl}`;
  const twitterShareUrl = `http://twitter.com/share?&url=${shareUrl}`;

  const toggleNavigatorShareMobile = async (event: SyntheticEvent) => {
    stopPropagation(event);

    if (window.innerWidth >= TABLET) {
      return;
    }

    const navigator = window.navigator as Navigator;
    if (navigator.share) {
      const shareData = {
        url: shareUrl,
      };
      try {
        await navigator.share(shareData);
      } catch (err) {
        if (err instanceof Error && err.name !== 'AbortError') {
          console.error(err);
          setShowCustomShare(!showCustomShare);
        }
      }
    } else {
      console.warn('Navigator share is not supported for your browser.');
    }
  };

  const copyUrlToClipboard = (event: SyntheticEvent) => {
    stopPropagation(event);

    navigator.clipboard.writeText(shareUrl).then(() => alert('Kopierade länk till urklipp'));
  };

  return (
    <SocialMediaLinksWrapper className={className} data-testid="social-media-sharing">
      {isNavigatorShare && !showCustomShare ? (
        <StyledSocialButton aria-label="Dela" onClick={toggleNavigatorShareMobile}>
          <ShareIcon size={24} />
        </StyledSocialButton>
      ) : (
        <>
          <StyledSocialButton onClick={copyUrlToClipboard} title="Kopiera länk" aria-label="Kopiera länk">
            <LinkIcon size={24} />
          </StyledSocialButton>
          <StyledSocialButton
            as="a"
            onClick={stopPropagation}
            title={`Dela på X`}
            aria-label={`Dela på X`}
            href={twitterShareUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon color="currentColor" size={24} />
          </StyledSocialButton>
          <StyledSocialButton
            as="a"
            onClick={stopPropagation}
            title={`Dela på Facebook`}
            aria-label={`Dela på Facebook`}
            href={facebookShareUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon color="currentColor" size={24} />
          </StyledSocialButton>
        </>
      )}
    </SocialMediaLinksWrapper>
  );
};

const SocialMediaLinksWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const StyledSocialButton = styled(SocialButton)`
  width: ${sizeUnits[40]};
  height: ${sizeUnits[40]};
`;
