import { useMemo } from 'react';

import type { NodeDataFieldsFragment } from '@news/gql';
import type { ArticleMeta, DocumentNode } from '@news/lib';
import { injectRichTextAds, isNotNullish } from '@news/lib';

import { renderNode } from 'lib/bodyRenderer';
import { TEST_ID } from 'lib/constants';

interface Props {
  body: DocumentNode;
  data?: NodeDataFieldsFragment[];
  articleMeta?: ArticleMeta;
  renderAds?: boolean;
}

export const BodyWrapper = ({ body, articleMeta, data, renderAds = false }: Props) => {
  const baseNode = useMemo(() => {
    if (renderAds && body) {
      return injectRichTextAds(body, data);
    }
    return body;
  }, [body, renderAds, data]);

  if (!baseNode) return null;

  return (
    <div data-testid={TEST_ID.body}>
      {renderNode({
        node: baseNode,
        options: {
          data: (data ?? []).filter(isNotNullish),
          articleMeta,
        },
      })}
    </div>
  );
};
