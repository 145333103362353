import styled from 'styled-components';

import { Typography } from 'components/Typography';

type Props = {
  text: string;
};

const Byline = ({ text }: Props) => {
  return <Wrapper variant="body3">{`Foto: ${text}`}</Wrapper>;
};

const Wrapper = styled(Typography)`
  margin-top: 6.4px;
  top: 100%;
`;

export { Byline };
