import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import type { ArticleFieldsFragment } from '@news/gql';
import { getBylines } from '@news/lib';

import { Typography } from 'components/Typography';
import { PREVENT_COLLAPSE_CLASS } from 'views/feed/helpers';

import { SocialMediaShareIcons } from './SocialMediaShareIcons';

type ArticleFooterProps = {
  byline?: ArticleFieldsFragment['byline']; // New field
  bylineName?: ArticleFieldsFragment['bylineName']; // Old field kept for backwards compatibility
  bylineEmail?: ArticleFieldsFragment['bylineEmail']; // Old field kept for backwards compatibility
  sharePath: string;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
};

export const ArticleFooter = ({
  byline = [],
  bylineEmail,
  bylineName,
  sharePath,
  className,
  onClick,
}: ArticleFooterProps) => {
  const bylines = useMemo(() => getBylines({ byline, bylineEmail, bylineName }), [byline, bylineEmail, bylineName]);
  const hasByline = bylines.length > 0;

  return (
    <ArticleFooterContainer className={className} onClick={onClick} data-testid="article-footer">
      {hasByline && (
        <div data-testid="article-footer-byline-container">
          {bylines.map((byline) => {
            const { name, email } = byline;

            return (
              <Byline key={`${name}-${email}`} className={PREVENT_COLLAPSE_CLASS}>
                <BylineName>{name}</BylineName>
                {email && <BylineEmail email={email}>{email}</BylineEmail>}
              </Byline>
            );
          })}
        </div>
      )}
      <StyledSocialMediaSharing path={sharePath}></StyledSocialMediaSharing>
    </ArticleFooterContainer>
  );
};

const BylineName = ({ children }: PropsWithChildren) => <Typography variant="body2-strong">{children}</Typography>;
const BylineEmail = ({ children, email }: PropsWithChildren & { email: string }) => (
  <StyledLink variant="body2" as="a" href={`mailto:${email}`}>
    {children}
  </StyledLink>
);

const StyledLink = styled(Typography)`
  color: ${colors.black[50]};
`;

const Byline = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${sizeUnits[8]} 0;
  border-bottom: 1px solid ${colors.gray.light};
  color: ${colors.black[50]};
  cursor: default;
`;

const StyledSocialMediaSharing = styled(SocialMediaShareIcons)`
  align-self: flex-end;
  padding: ${sizeUnits[8]} 0;
`;

const ArticleFooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  gap: ${sizeUnits[8]};
  cursor: pointer;
`;
