import type { RefObject } from 'react';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';

import { ImageProxy } from 'lib/imageProxy';
import { theme } from 'styles/theme';
import { PREVENT_COLLAPSE_CLASS } from 'views/feed/helpers';

import { Typography } from './Typography';
import { CloseIcon } from './icons';

const FullscreenImageModal = ({
  fullscreenImageModalRef,
  src,
  caption,
}: {
  fullscreenImageModalRef: RefObject<HTMLDialogElement>;
  src?: string | null;
  caption: string | null;
}) => {
  const closeDialog = useCallback(() => {
    (document.querySelector('body') as HTMLBodyElement).style.overflow = 'auto';
    fullscreenImageModalRef.current?.close();
  }, [fullscreenImageModalRef]);

  useEffect(() => {
    const dialogElement = fullscreenImageModalRef.current as HTMLDialogElement;

    const closeOnBackDropClick = (event: MouseEvent) => {
      const rect = dialogElement.getBoundingClientRect();
      const isInDialog =
        rect.top <= event.clientY &&
        event.clientY <= rect.top + rect.height &&
        rect.left <= event.clientX &&
        event.clientX <= rect.left + rect.width;
      if (!isInDialog) {
        closeDialog();
      }
    };

    dialogElement.addEventListener('cancel', closeDialog);
    dialogElement.addEventListener('close', closeDialog);
    dialogElement.addEventListener('click', closeOnBackDropClick);

    return () => {
      dialogElement.removeEventListener('cancel', closeDialog);
      dialogElement.removeEventListener('close', closeDialog);
      dialogElement.removeEventListener('click', closeOnBackDropClick);
    };
  }, [fullscreenImageModalRef, closeDialog]);

  return (
    <StyledDialog ref={fullscreenImageModalRef} className={PREVENT_COLLAPSE_CLASS}>
      <StyledFigure>
        <StyledIcon onClick={closeDialog}>
          <CloseIcon color={colors.white[100]} size={24} />
        </StyledIcon>
        <StyledFullScreenImage loading="lazy" src={ImageProxy.url({ url: src, width: 1024 })} />
        {caption && (
          <ImageCaption>
            <Typography variant="caption">{caption}</Typography>
          </ImageCaption>
        )}
      </StyledFigure>
    </StyledDialog>
  );
};

export { FullscreenImageModal };

const StyledIcon = styled.button`
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  border-radius: 50%;
  display: grid;
  background-color: rgb(0 0 0 / 0.15);
  backdrop-filter: blur(10px);

  ${theme.mq.tablet} {
    right: 10px;
    top: 10px;
  }
`;

const StyledDialog = styled.dialog`
  border: 0;
  padding: 0;
  max-width: 100vw;
  max-height: 100svh;
  cursor: auto;

  &::backdrop {
    background: rgb(0 0 0 / 0.75);
    backdrop-filter: blur(10px);
    cursor: pointer;
  }
`;

const StyledFigure = styled.figure`
  display: grid;
  margin: 0;
`;

const ImageCaption = styled.figcaption`
  position: absolute;
  bottom: 0;
  left: 0;
  color: ${colors.white[100]};
  width: 100%;
  background: ${colors.black[25]};
  backdrop-filter: blur(10px);
  padding: 6.4px 12px;
  ${theme.mq.tablet} {
    padding: 12px 16px;
  }
`;

const StyledFullScreenImage = styled.img`
  max-height: 100svh;
  max-width: 100vw;
  object-fit: contain;
`;
