import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';

import { Typography } from 'components/Typography';
import { theme } from 'styles/theme';

function ImageOverlay({ caption }: { caption?: string }) {
  return (
    <ImageOverlayStack>
      {caption && (
        <Caption>
          <Typography variant="caption">{caption}</Typography>
        </Caption>
      )}
    </ImageOverlayStack>
  );
}

export const ImageOverlayStack = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0;
  width: 100%;
`;

export const Caption = styled.span`
  color: ${colors.white[100]};
  width: 100%;
  background: rgb(0 0 0 / 0.4);
  backdrop-filter: blur(32px);
  padding: 6.4px 12px;
  ${theme.mq.tablet} {
    padding: 12px 16px;
  }
`;

export { ImageOverlay };
