import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import { GameLocation, League, useSportStandingByLeagueQuery } from '@news/gql';
import type { ArticleBody } from '@news/lib';

import { SwitchGroup } from 'components/SwitchGroup';
import { Typography } from 'components/Typography';
import { Chevron } from 'components/icons';
import { SportResultsLoading } from 'components/loading';
import { feedItemBoxShadow } from 'styles/mixins/boxShadow';
import { SECONDARY_TAB_MENU_HEIGHT_DESKTOP, TOPBAR_HEIGHT_DESKTOP } from 'styles/theme';

import { Error } from './components/Error';
import { StandingsTable } from './components/StandingsTable';

type SportStandingTableData = ArticleBody<'SportStandingsTableData'>;

const leagueMapper = new Map<League, string>([
  [League.Hockeyallsvenskan, 'Hockeyallsvenskan'],
  [League.Shl, 'SHL'],
]);

export const SportStandingTable = ({
  leagues,
  defaultLeague,
}: {
  defaultLeague: SportStandingTableData['defaultLeague'];
  leagues: SportStandingTableData['leagues'];
}) => {
  const [league, setLeague] = useState<League>(defaultLeague ?? League.Shl);
  const [isExpanded, setIsExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  function setShlFirst(leagues: League[]) {
    if (!leagues.includes(League.Shl)) {
      return leagues;
    }
    const filteredLeagues = leagues.filter((league) => league !== League.Shl);
    return [League.Shl, ...filteredLeagues];
  }

  const sortedLeagues = setShlFirst(leagues);
  const options = sortedLeagues.map((league) => ({
    label: leagueMapper.get(league) ?? '',
    value: league,
  }));

  const { data, error, loading } = useSportStandingByLeagueQuery({
    variables: { league, gameLocationFilter: [GameLocation.Total] },
  });

  const toggleViewMore: React.MouseEventHandler = useCallback(
    (event) => {
      event.stopPropagation();
      setIsExpanded(!isExpanded);
      if (isExpanded) {
        containerRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [isExpanded]
  );

  if (loading) return <SportResultsLoading />;
  if (error) return <Error />;

  return (
    <Wrapper ref={containerRef} $isExpanded={isExpanded}>
      <ContentHeader>
        <LeaugeTitle variant="body1-strong">{`Säsongen ${data?.sportStandingByLeague.name}`}</LeaugeTitle>
        <SwitchGroup options={options} value={league} onSelect={setLeague} />
      </ContentHeader>
      <StandingsTableWrapper $isExpanded={isExpanded}>
        {data?.sportStandingByLeague && <StandingsTable data={data?.sportStandingByLeague} />}
      </StandingsTableWrapper>
      <ExpandCollapseButtonWrapper $isExpanded={isExpanded}>
        <ExpandCollapseButton $isExpanded={isExpanded} onClick={toggleViewMore}>
          <ExpandCollapseButtonText variant="CTA">Visa {isExpanded ? 'mindre' : 'mer'}</ExpandCollapseButtonText>
          <Chevron size={20} direction={isExpanded ? 'up' : 'down'} />
        </ExpandCollapseButton>
      </ExpandCollapseButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${sizeUnits[8]} ${sizeUnits[12]};
  gap: ${sizeUnits[8]};
  ${(props) => !props.$isExpanded && fade}
  border: 1px solid ${colors.gray.dark};
  position: relative;
  scroll-behavior: smooth;
  scroll-margin-top: calc(${TOPBAR_HEIGHT_DESKTOP}px + ${SECONDARY_TAB_MENU_HEIGHT_DESKTOP}px + ${sizeUnits[40]});
`;

const LeaugeTitle = styled(Typography)`
  white-space: nowrap;
  margin: auto;
  ${({ theme }) => theme.mq.tablet} {
    margin: unset;
  }
`;

const fade = css`
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25%;
    background: linear-gradient(0deg, ${colors.gray.fade}, 80%, transparent);
  }
`;

const ContentHeader = styled.div`
  ${({ theme }) => theme.mq.tablet} {
    flex-direction: row;
    justify-content: space-between;
    margin: unset;
  }

  display: flex;
  flex-direction: column;
  margin: auto;
  gap: ${sizeUnits[12]};
  place-items: baseline;
`;

const StandingsTableWrapper = styled.div<{ $isExpanded: boolean }>`
  ${({ $isExpanded }) => (!$isExpanded ? `max-height: 370px` : null)};
  overflow: scroll;
  overflow-y: hidden;
`;

const ExpandCollapseButtonWrapper = styled.div<{ $isExpanded: boolean }>`
  margin: ${({ $isExpanded }) => ($isExpanded ? `5px 0 20px` : `0px`)};
  margin-top: 0;
  z-index: 1;
`;
const ExpandCollapseButton = styled.button<{ $isExpanded: boolean }>`
  padding: ${sizeUnits[12]};
  border-radius: 5px;
  width: 100%;
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
  background-color: ${({ $isExpanded }) => ($isExpanded ? `${colors.gray.fade}` : `${colors.white[100]}`)};
  ${({ $isExpanded }) => (!$isExpanded ? feedItemBoxShadow : null)};
`;

const ExpandCollapseButtonText = styled(Typography)`
  color: ${colors.black[100]};
`;
