import { Ratio } from '@news/gql';

interface RatioFormat {
  padding: '56.25%' | '125%' | '100%' | '150%';
  string: '16 / 9' | '4 / 5' | '2 / 3' | '1 / 1';
}

const RATIO_MAP: Record<Ratio, RatioFormat> = {
  [Ratio.Ratio_16x9]: {
    padding: '56.25%',
    string: '16 / 9',
  },
  [Ratio.Ratio_4x5]: {
    padding: '125%',
    string: '4 / 5',
  },
  [Ratio.Ratio_1x1]: {
    padding: '100%',
    string: '1 / 1',
  },
  [Ratio.Ratio_2x3]: {
    padding: '150%',
    string: '2 / 3',
  },
};

export const ratioString = (ratio: Ratio): RatioFormat['string'] => {
  const value = RATIO_MAP[ratio]?.string;
  if (!value) {
    console.error('invalid ratio passed to ratioPadding: ', ratio);
  }

  return value || '1 / 1';
};
