import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { Typography } from 'components/Typography';
import { Caption, ImageOverlayStack } from 'views/feed/components/featured-image/ImageOverlay';

import { AnimatedPlayButton } from './AnimatedPlayButton';

interface FeaturedVideoOverlayProps {
  duration?: number;
  humanDuration?: string;
  caption?: string;
  hideDuration?: boolean;
  hideCaption?: boolean;
}

export const FeaturedVideoOverlay = ({
  duration,
  humanDuration,
  hideCaption,
  hideDuration,
  caption,
}: FeaturedVideoOverlayProps) => (
  <ImageOverlayStack>
    <TopStackContent>
      <PlayButtonContainer>
        <AnimatedPlayButton />
      </PlayButtonContainer>
      {!hideDuration && humanDuration && (
        <TimeOverlay
          itemProp="duration"
          as="time"
          dateTime={duration ? `PT${duration}S` : undefined}
          variant="metadata"
        >
          {humanDuration}
        </TimeOverlay>
      )}
    </TopStackContent>

    {!hideCaption && caption && (
      <Caption>
        <Typography variant="caption">{caption}</Typography>
      </Caption>
    )}
  </ImageOverlayStack>
);

const TopStackContent = styled.div`
  position: relative;
`;

const PlayButtonContainer = styled.div`
  position: absolute;
  left: 12px;
  bottom: 12px;
`;

const TimeOverlay = styled(Typography)`
  position: absolute;
  bottom: ${sizeUnits[16]};
  right: ${sizeUnits[16]};
  border-radius: 2px;
  backdrop-filter: blur(${sizeUnits[4]});
  background-color: ${colors.black[40]};
  color: ${colors.white[100]};
  padding: ${sizeUnits[4]} ${sizeUnits[8]};
`;
