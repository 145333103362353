import { Fragment, useRef } from 'react';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import type { SportStandingByLeagueQuery } from '@news/gql';

import { Typography } from 'components/Typography';
import { theme } from 'styles/theme';

export const StandingsTable = ({ data }: { data: SportStandingByLeagueQuery['sportStandingByLeague'] }) => {
  const tableRef = useRef<HTMLTableElement>(null);

  const showDivider = ({ position }: { position: number }) => {
    return data.lines.line.some((line, index) => {
      // Last line is not a divider, only show the start line as divider
      if (index === data.lines.line.length - 1) {
        return line.start - 1 === position;
      }
      return line.end === position;
    });
  };

  return (
    <StyledTable ref={tableRef}>
      <thead>
        <tr>
          <TableHeaderCell $fixedWidthMobile={150}>
            <TeamLogo
              style={{ width: data.primary.name.value === 'SHL' ? '60px' : '30px' }}
              $id={data.primary.name.value.toLocaleLowerCase()}
            />
          </TableHeaderCell>
          <TableHeaderCell>S</TableHeaderCell>
          <TableHeaderCell>V</TableHeaderCell>
          <TableHeaderCell>VÖ</TableHeaderCell>
          <TableHeaderCell>FÖ</TableHeaderCell>
          <TableHeaderCell>F</TableHeaderCell>
          <TableHeaderCell $shouldHideOnMobile>M</TableHeaderCell>
          <TableHeaderCell>+/-</TableHeaderCell>
          <TableHeaderCell>
            <Typography style={{ fontSize: '14px' }} variant="body1-strong">
              P
            </Typography>
          </TableHeaderCell>
        </tr>
      </thead>
      <tbody>
        {data?.participant.map((participant, index) => (
          <Fragment key={participant.id}>
            {participant.result.map((result) => (
              <>
                <TableRow key={result.id} $hasDivider={showDivider({ position: index + 1 })}>
                  <TableDataCell>
                    <RowDetail>
                      <div style={{ display: 'flex', alignItems: 'center', gap: sizeUnits[8] }}>
                        <TableCellText variant="body1-strong">{index + 1}</TableCellText>
                        <TeamLogo $id={participant.id} />
                      </div>
                      <TableCellText variant="body1-strong">{participant.name.value}</TableCellText>
                    </RowDetail>
                  </TableDataCell>
                  <TableDataCell>
                    <TableCellText variant="body1-strong">{result.gamesplayed}</TableCellText>
                  </TableDataCell>
                  <TableDataCell>
                    <TableCellText variant="body1-strong">{result.win}</TableCellText>
                  </TableDataCell>
                  <TableDataCell>
                    <TableCellText variant="body1-strong">{result.wp}</TableCellText>
                  </TableDataCell>
                  <TableDataCell>
                    <TableCellText variant="body1-strong">{result.lp}</TableCellText>
                  </TableDataCell>
                  <TableDataCell>
                    <TableCellText variant="body1-strong">{result.lost}</TableCellText>
                  </TableDataCell>
                  <TableDataCell $shouldHideOnMobile>
                    <TableCellText variant="body1-strong">{`${result.scoreteam} - ${result.goalsconceded}`}</TableCellText>
                  </TableDataCell>
                  <TableDataCell>
                    <TableCellText variant="body1-strong">
                      {result.goaldifference > 0 ? `+ ${result.goaldifference}` : `${result.goaldifference}`}
                    </TableCellText>
                  </TableDataCell>
                  <TableDataCell style={{ fontWeight: '900' }}>
                    <TableCellText variant="body1-strong">{result.points}</TableCellText>
                  </TableDataCell>
                </TableRow>
              </>
            ))}
          </Fragment>
        ))}
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled('table')`
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
  table-layout: fixed;

  ${theme.mq.tablet} {
    table-layout: auto;
  }
`;

const TableCellText = styled(Typography)`
  font-size: 14px;
  ${theme.mq.tablet} {
    font-size: 16px;
  }
`;

const TableHeaderCell = styled('th')<{ $shouldHideOnMobile?: boolean; $fixedWidthMobile?: number }>`
  background-clip: padding-box;
  background-color: ${colors.gray.fade};
  border: 1px solid ${colors.white[100]};
  border-right: none;
  border-left: none;
  border-collapse: collapse;
  padding: 5px 2px;
  font-weight: 400;
  position: relative;
  text-align: center;
  ${({ $shouldHideOnMobile }) => $shouldHideOnMobile && 'display: none'};
  ${({ $fixedWidthMobile }) => $fixedWidthMobile && `width: ${$fixedWidthMobile}px`};

  ${theme.mq.tablet} {
    display: table-cell;
    width: auto;
  }
`;

const TableRow = styled('tr')<{ $hasDivider: boolean }>`
  border: 1px solid ${({ $hasDivider }) => ($hasDivider ? `${colors.black[100]}` : `${colors.white[100]}`)};
`;

const TableDataCell = styled('td')<{ $shouldHideOnMobile?: boolean }>`
  border-collapse: collapse;
  background-color: ${colors.gray.fade};
  border-right: none;
  border-left: none;
  padding: 0;
  min-width: 48px;
  position: relative;
  text-align: center;

  ${({ $shouldHideOnMobile }) => $shouldHideOnMobile && 'display: none'};

  ${theme.mq.tablet} {
    display: table-cell;
    white-space: nowrap;
    padding: 5px 6px;
  }
`;

const RowDetail = styled.span`
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: ${sizeUnits[4]};
  gap: ${sizeUnits[8]};
  text-align: left;

  ${theme.mq.tablet} {
    padding: ${sizeUnits[8]};
    gap: ${sizeUnits[20]};
  }
`;

const TeamLogo = styled.div<{ $id: string }>`
  position: relative;
  width: 25px;
  height: 30px;

  ${theme.mq.tablet} {
    width: 30px;
  }

  &::before {
    content: '';
    position: absolute;
    background-image: ${({ $id }) => `url(/assets/images/team-logos/${$id}.svg)`};
    background-repeat: no-repeat;
    background-size: 100% 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
