import type { ReactNode } from 'react';
import React from 'react';

const toBrTags = (str: unknown): React.ReactElement => {
  if (typeof str === 'string') {
    const output = str.split('\n').reduce((children: ReactNode[], textSegment, index) => {
      // eslint-disable-next-line react/no-array-index-key
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
    return <>{output}</>;
  }
  return <></>;
};
export { toBrTags };
