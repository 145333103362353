import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { Typography } from 'components/Typography';

import { AnimatedPlayButton } from './AnimatedPlayButton';

interface VideoAssetOverlayProps {
  duration?: number;
  humanDuration?: string;
  caption?: string;
  hideDuration?: boolean;
  published?: string;
}

export const VideoAssetOverlay = ({
  duration,
  humanDuration,
  caption,
  hideDuration = false,
  published,
}: VideoAssetOverlayProps) => (
  <>
    <PublishedText variant="metadata">{published}</PublishedText>
    <VideoAssetOverlayWrapper>
      <PlayButtonContainer $hideDuration={hideDuration}>
        <AnimatedPlayButton>
          {!hideDuration && humanDuration && (
            <PlayButtonDuration variant="metadata" as="time" dateTime={duration ? `PT${duration}S` : undefined}>
              {humanDuration}
            </PlayButtonDuration>
          )}
        </AnimatedPlayButton>
      </PlayButtonContainer>
      <VideoAssetCaption variant="title3-strong" as="h3">
        {caption}
      </VideoAssetCaption>
    </VideoAssetOverlayWrapper>
  </>
);

const PlayButtonDuration = styled(Typography)`
  position: absolute;
  left: 40px;
  text-align: center;
  width: calc(100px - 40px);
  color: ${colors.white[100]};
  padding: 8;
`;

const PlayButtonContainer = styled.div<{ $hideDuration: boolean }>`
  position: relative;
  background-color: ${({ $hideDuration }) => !$hideDuration && colors.black[100]};
  border-radius: 8px;
  display: flex;
  align-items: center;
  min-width: 100px;
`;

const VideoAssetCaption = styled(Typography)`
  color: ${colors.white[100]};
`;

const VideoAssetOverlayWrapper = styled.div`
  position: absolute;
  padding: ${sizeUnits[8]} ${sizeUnits[12]};
  gap: ${sizeUnits[8]};
  width: 100%;
  bottom: 0;
  background: ${colors.black[50]};
  backdrop-filter: blur(2.5px);
  display: flex;
  align-items: center;
`;

const PublishedText = styled(Typography)`
  position: absolute;
  background-color: ${colors.black[100]};
  top: 0;
  left: 0;
  color: ${colors.white[100]};
  padding: ${sizeUnits[4]} ${sizeUnits[12]};
  border-bottom-right-radius: ${sizeUnits[8]};
`;
