import throttle from 'lodash.throttle';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { LeftSliderArrowIcon, RightSliderArrowIcon } from 'components/icons';
import { hideScrollbar } from 'styles/mixins/hideScrollbar';
import type { Theme } from 'styles/theme';
import { MAX_CONTENT_WIDTH, theme } from 'styles/theme';

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

const Track = styled.div`
  height: 100%;
  width: 100%;
  margin-left: 0;
  overflow: scroll hidden;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  ${hideScrollbar()}
`;

const TrackInner = styled.div`
  display: flex;
`;

const Item = styled.div`
  height: 100%;
  scroll-snap-align: start;

  &:last-child {
    scroll-snap-align: end;
  }

  a {
    text-decoration: none !important;
  }

  &:focus-within {
    transform: scale(1.01);
    opacity: 0.9;
  }
`;

const Button = styled.button`
  background: white;
  border: 0;
  border-radius: 6px;
  height: 48px;
  width: 48px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;
type ButtonProps = {
  $top?: number;
};

const LeftButton = styled(Button)<ButtonProps>`
  left: ${sizeUnits[16]};
  ${theme.mq.tablet} {
    left: 46px;
  }

  top: ${(props) => (props.$top ? `${props.$top}px` : 0)};
`;
const RightButton = styled(Button)<ButtonProps>`
  right: ${sizeUnits[16]};
  ${({ theme }) => theme.mq.tablet} {
    right: 46px;
  }

  top: ${(props) => (props.$top ? `${props.$top}px` : 0)};
`;

const Dots = styled.div`
  display: flex;
  justify-content: center;
  padding: ${sizeUnits[8]} 0;
`;

const Dot = styled.button<{ $isActive: boolean; theme: Theme }>`
  background: ${(props) => (props.$isActive ? colors.red.tv4 : '#a3a3a3')};
  border-radius: 3px;
  border: 0;
  display: block;
  height: 6px;
  margin-right: ${sizeUnits[8]};
  padding: 0;
  width: 6px;

  &:last-child {
    margin-right: 0;
  }
`;

type SliderProps = {
  children: React.ReactNode;
  className?: string;
  width: number;
  itemWidth: number;
  scrollWidth: number;
  showArrows?: boolean;
  showDots?: boolean;
  verticalCenter?: number;
  embedded?: boolean;
};

const Slider = ({
  children,
  className,
  itemWidth,
  scrollWidth,
  showArrows = true,
  showDots = true,
  verticalCenter,
  width,
}: SliderProps) => {
  const trackRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemCount = React.Children.toArray(children).length;
  const adjustedWidth = width > MAX_CONTENT_WIDTH ? MAX_CONTENT_WIDTH : width;
  const slides = Math.ceil(adjustedWidth / itemWidth);

  useEffect(() => {
    // Keeps the items aligned to the grid when resizing. Could probably be
    // done in a better way?
    if (trackRef.current) {
      trackRef.current.scroll({
        left: trackRef.current.scrollLeft,
        behavior: 'smooth',
      });
    }
  }, [width]);

  const onScroll = throttle(() => {
    if (trackRef.current) {
      const scrollLeft = trackRef.current.scrollLeft;

      if (scrollLeft === 0) {
        setCurrentIndex(0);
      } else if (scrollLeft === scrollWidth - adjustedWidth) {
        setCurrentIndex(itemCount - slides);
      } else {
        setCurrentIndex(Math.ceil(scrollLeft / itemWidth));
      }
    }
  }, 80);

  const scroll = (newIndex: number) => {
    if (trackRef.current) {
      trackRef.current.scroll({
        left: newIndex * itemWidth,
        behavior: 'smooth',
      });
    }
  };

  const shouldShowLeftButton = showArrows && currentIndex > 0;
  const shouldShowRightButton = showArrows && currentIndex < itemCount - slides;

  return (
    <Wrapper className={className}>
      <Track ref={trackRef} onScroll={onScroll}>
        <TrackInner
          style={{
            width: scrollWidth,
          }}
        >
          {React.Children.toArray(children).map((child: React.ReactNode, index: number) => (
            <Item
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              style={{
                width: itemWidth,
              }}
            >
              {child}
            </Item>
          ))}
        </TrackInner>
      </Track>
      {shouldShowLeftButton && (
        <LeftButton aria-label="Bakåt" onClick={() => scroll(currentIndex - slides)} $top={verticalCenter}>
          <LeftSliderArrowIcon />
        </LeftButton>
      )}
      {shouldShowRightButton && (
        <RightButton aria-label="Framåt" onClick={() => scroll(currentIndex + slides)} $top={verticalCenter}>
          <RightSliderArrowIcon />
        </RightButton>
      )}
      {showDots && (
        <Dots>
          {[...Array(Math.ceil(itemCount / slides)).keys()].map((index) => (
            <Dot
              key={index}
              $isActive={currentIndex >= index * slides && currentIndex < index * slides + slides}
              onClick={() => scroll(index * slides)}
            />
          ))}
        </Dots>
      )}
    </Wrapper>
  );
};
export { Slider };
