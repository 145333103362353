import dynamic from 'next/dynamic';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { type VideoAssetFieldsFragment } from '@news/gql';
import { FALLBACK_ARTICLE_IMAGE } from '@news/lib';

import { VideoImageOverlay } from 'components/video-image-overlay';
import { useActiveVideoPlayerContext } from 'contexts/VideoPlayerContext';

import { FeaturedImage } from '../featured-image/FeaturedImage';

const AvodPlayerWrapper = dynamic(() => import('./AvodPlayerWrapper').then((module) => module.AvodPlayerWrapper), {
  ssr: false,
});

type Props = {
  variant: 'featured' | 'video-asset';
  videoAsset: VideoAssetFieldsFragment;
  thumbnailOverride?: string;
  captionOverride?: string;
  byline?: string;
  hideText?: boolean;
  videoPlayerId: string;
};

export const VideoPlayer = ({
  variant,
  videoAsset,
  thumbnailOverride,
  captionOverride,
  byline,
  hideText,
  videoPlayerId,
}: Props) => {
  const { activeVideoPlayerId, setActiveVideoPlayerId } = useActiveVideoPlayerContext();

  const isActive = activeVideoPlayerId === videoPlayerId;
  const shouldStartMutedRef = useShouldStartMutedRef(isActive);

  const onClick = () => {
    shouldStartMutedRef.current = false;
    setActiveVideoPlayerId(videoPlayerId);
  };

  const image = thumbnailOverride || videoAsset.image || FALLBACK_ARTICLE_IMAGE;
  const caption = captionOverride || videoAsset.title;

  return (
    <VideoPlayerContainer>
      {isActive && (
        <AvodPlayerWrapper videoAsset={videoAsset} image={image} shouldStartMuted={shouldStartMutedRef.current} />
      )}
      {!isActive && (
        <VideoPlayerPlaceholderContainer role="button" tabIndex={-1} onClick={onClick}>
          <FeaturedImage
            image={image}
            byline={hideText ? undefined : byline}
            overlay={
              <VideoImageOverlay
                variant={variant}
                duration={videoAsset.duration || undefined}
                humanDuration={videoAsset.humanDuration || undefined}
                published={videoAsset.humanPublishedDateTime || undefined}
                caption={caption}
                hideCaption={hideText}
                hideDuration={videoAsset.live ?? false}
              />
            }
          />
        </VideoPlayerPlaceholderContainer>
      )}
    </VideoPlayerContainer>
  );
};

export const VideoPlayerImage = ({
  videoAsset,
  thumbnailOverride,
}: Pick<Props, 'videoAsset' | 'thumbnailOverride'>) => {
  const image = thumbnailOverride || videoAsset.image || FALLBACK_ARTICLE_IMAGE;

  return (
    <VideoPlayerContainer>
      <FeaturedImage image={image} />
    </VideoPlayerContainer>
  );
};

const useShouldStartMutedRef = (isActive: boolean) => {
  const shouldStartMutedRef = useRef(true);

  useEffect(() => {
    if (!isActive) {
      //when a video that was manually played is closed (!isActive)
      //we need to set shouldStartMuted to true so that it starts muted next time autoplay is triggered
      shouldStartMutedRef.current = true;
    }
  }, [isActive]);

  return shouldStartMutedRef;
};

const VideoPlayerContainer = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
`;

const VideoPlayerPlaceholderContainer = styled.div`
  cursor: pointer;
`;
