import { useCallback, useEffect, useRef, useState } from 'react';

import { useWindowSize } from './useWindowSize';

const useSize = () => {
  const ref = useRef<HTMLElement | null>(null);
  const setRef = useCallback((node: HTMLElement | null) => {
    if (node) {
      const rect = node.getBoundingClientRect();
      setSize({
        width: rect.width,
        height: rect.height,
      });
    }

    ref.current = node;
  }, []);

  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [{ width, height }, setSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setSize({
        width: rect.width,
        height: rect.height,
      });
    }
  }, [windowWidth, windowHeight, ref]);

  return { ref: setRef, width, height };
};

export { useSize };
