import styled from 'styled-components';

import { theme } from 'styles/theme';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledIframe = styled.iframe`
  border: none;
  background-color: white;
  display: block;
  width: 100%;
  max-height: 90px;
  ${theme.mq.tablet} {
    max-height: 120px;
  }
`;

export const PowerOfBalance = () => {
  return (
    <Wrapper>
      <StyledIframe src="/power-of-balance.html" scrolling="no" />
    </Wrapper>
  );
};
