import { useMemo } from 'react';
import styled from 'styled-components';
import { ItemTracker } from 'tracking/ItemTracker';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import type { ArticleBody, ArticleMeta } from '@news/lib';
import { appendUtmTags } from '@news/tracking';

import { Typography } from 'components/Typography';
import { PrimaryButton } from 'components/buttons';
import { BigArrow, PlayIconFilled } from 'components/icons';
import { ImageProxy } from 'lib/imageProxy';
import { PREVENT_COLLAPSE_CLASS } from 'views/feed/helpers';

const IMAGE_WIDTH = 132;
const IMAGE_HEIGHT = 132 * 1.5;

interface EditorialBannerProps {
  data: ArticleBody<'EditorialBannerData'>;
  articleMeta?: ArticleMeta;
}

export const EditorialBanner = ({ data, articleMeta }: EditorialBannerProps) => {
  const isTv4PlayLink = new URL(data.buttonLink).hostname.endsWith('tv4play.se');

  const imageUrl = useMemo(() => {
    if (!data.bannerImage) {
      return undefined;
    }
    return ImageProxy.url({ url: data.bannerImage.url, width: IMAGE_WIDTH * 2 });
  }, [data.bannerImage]);

  return (
    <EditorialBannerWrapper className={PREVENT_COLLAPSE_CLASS}>
      <EditorialBannerLeftContent>
        <Typography variant="title2" as="h3">
          {data.bannerTitle}
        </Typography>
        <Typography variant="body1">{data.bannerText}</Typography>

        <EditorialBannerButtonWrapper>
          <ItemTracker item={{ ...data, articleMeta }}>
            <EditorialBannerButton href={appendUtmTags(data.buttonLink, 'EditorialBanner')} target="_blank" as="a">
              <Typography variant="CTA">{data.buttonText}</Typography>
              {isTv4PlayLink ? <PlayIconFilled /> : <BigArrow size={20} />}
            </EditorialBannerButton>
          </ItemTracker>
        </EditorialBannerButtonWrapper>
      </EditorialBannerLeftContent>

      {imageUrl && (
        <EditorialBannerImage
          loading="lazy"
          alt={data.bannerImage?.title ?? undefined}
          width={IMAGE_WIDTH}
          height={IMAGE_HEIGHT}
          src={imageUrl}
        />
      )}
    </EditorialBannerWrapper>
  );
};

const EditorialBannerWrapper = styled.div`
  overflow: hidden;
  position: relative;
  cursor: auto;
  border-left: ${sizeUnits[4]} solid ${colors.red.tv4};
  display: flex;
  justify-content: space-between;
  min-height: ${IMAGE_HEIGHT}px;
  margin: ${sizeUnits[12]} 0;
`;

const EditorialBannerLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${sizeUnits[12]};
  padding: ${sizeUnits[16]};
  padding-right: 0;
`;

const EditorialBannerButton = styled(PrimaryButton)`
  display: flex;
  gap: ${sizeUnits[4]};
  color: ${colors.black[70]};
`;

const EditorialBannerButtonWrapper = styled.div`
  margin-top: auto;
  align-self: flex-start;
`;

const EditorialBannerImage = styled.img`
  margin-top: auto;
`;
