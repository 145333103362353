import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { PlayIconRounded } from 'components/icons';
import { theme } from 'styles/theme';

export const AnimatedPlayButton = ({ children }: React.PropsWithChildren) => {
  return (
    <>
      {children}
      <PlayButtonWrapper aria-label="Spela video">
        <PlayIconRounded size={24} stroke="white" />
      </PlayButtonWrapper>
    </>
  );
};

const PlayButtonWrapper = styled.button`
  position: relative;
  overflow: hidden;
  background-color: ${colors.red.tv4};
  border-radius: ${sizeUnits[8]};
  padding: ${sizeUnits[8]};
  line-height: 0;
  transition: width 0.1s ease-in;
  width: 40px;
  text-align: right;
  ${theme.mq.hoverSupported} {
    &:focus,
    &:hover {
      background: ${colors.red.tv4};
      width: 100px;
    }

    &::before {
      content: 'Spela';
      position: absolute;
      color: ${colors.white[100]};
      top: 12px;
      right: 42px;
      line-height: 1;
      font-size: 18px;
      font-weight: 500;
    }
  }
`;
