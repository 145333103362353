import styled from 'styled-components';

import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import { colors } from '@news/lib';

export const Error = () => {
  return (
    <EmptyContainer>
      <Description>Tyvärr kan vi inte visa några matchresultat just nu</Description>
    </EmptyContainer>
  );
};

const EmptyContainer = styled.div`
  padding: ${sizeUnits[40]};
  height: 100%;
  display: flex;
  font-size: ${sizeUnits[24]};
  flex-direction: column;
  gap: ${sizeUnits[24]};
  align-items: center;
  justify-content: center;
  background-color: ${colors.grey.light};
  border-radius: ${sizeUnits[4]};
`;

const Description = styled.p`
  font-size: ${sizeUnits[16]};
  color: ${colors.grey.default};
`;
