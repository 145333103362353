import type { ReactNode } from 'react';
import type { CSSProperties } from 'styled-components';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import type { Ratio } from '@news/gql';

import { ImageProxy } from 'lib/imageProxy';

import { ratioString } from '../styles/ratio';

interface LazyImageProps {
  src?: string | null;
  srcSet?: string;
  sizes?: string;
  alt?: string | null;
  ratio?: Ratio | null;
  width?: number;
  contain?: boolean;
  background?: CSSProperties['background'];
  children?: ReactNode;
  className?: string;
}

const LazyImage = ({
  src,
  srcSet,
  sizes,
  alt,
  ratio,
  contain,
  width = 1024,
  background = colors.gray.light,
  children,
  className,
}: LazyImageProps) => {
  return (
    <ImageContainer
      className={`lazy-image-wrapper ${className ?? ''}`}
      $background={background}
      $aspectRatio={ratio ? ratioString(ratio) : 'auto'}
    >
      <StyledImage
        itemProp="contentUrl"
        $contain={typeof contain !== 'undefined' ? contain : true}
        loading="lazy"
        src={ImageProxy.url({ url: src, width })}
        sizes={sizes}
        srcSet={srcSet}
        alt={alt ?? ''}
      />
      {children}
    </ImageContainer>
  );
};

const StyledImage = styled.img<{ $contain?: boolean }>`
  max-width: 100%;
  width: 100%;
  object-fit: ${(props) => (props.$contain ? 'contain' : 'cover')};
`;

const ImageContainer = styled.div<{
  $aspectRatio: CSSProperties['aspectRatio'];
  $background?: CSSProperties['background'];
}>`
  display: flex;
  overflow: clip;
  position: relative;
  background: ${(props) => props.$background};
  aspect-ratio: ${(props) => props.$aspectRatio || 'auto'};
  height: 100%;
`;

export { LazyImage };
