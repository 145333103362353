import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { Typography } from 'components/Typography';
import { BODY_WIDTH_MOBILE, theme } from 'styles/theme';

export const VideoDescriptionHeadline = styled(Typography)`
  margin-bottom: 4px;
  color: ${colors.black[60]};
`;

export const VideoDescriptionText = styled(Typography)`
  color: ${colors.black[100]};
`;

export const Paragraph = styled(Typography)`
  margin: 12px 0;
`;

export const Figure = styled.figure`
  margin: 12px 0;
  max-width: ${BODY_WIDTH_MOBILE};
`;

export const Figcaption = styled.figcaption`
  position: absolute;
  bottom: 0;
  left: 0;
  color: ${colors.white[100]};
  width: 100%;
  background: ${colors.black[25]};
  backdrop-filter: blur(10px);
  padding: 6.4px 12px;
  font-size: 13px;
  font-weight: ${theme.fontWeights.normal};
`;

export const VideoDescription = styled.figcaption`
  text-align: center;
  background: ${colors.gray.light};

  & > blockquote::after {
    content: '“';
    display: block;
    color: ${colors.black[60]};
    font-size: 20px;
    margin-top: ${sizeUnits[8]};
    line-height: 1;
  }

  ${theme.mq.tablet} {
    padding: ${sizeUnits[28]};
  }
`;

export const EmbeddedVideoWrapper = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  margin: 12px 0;
  background: ${colors.gray.dark};
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 0;
`;

export const PollIframe = styled.iframe`
  width: 100%;
  height: 400px;
  margin: 12px 0;
  border: 0;
  overflow: scroll;
`;
