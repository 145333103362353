import styled from 'styled-components';

import { colors } from '@news/lib';

type Option<T> = { value: T; label: string };

type Props<T> = {
  options: Array<Option<T>>;
  onSelect: (option: T) => void;
  value: T | undefined;
};
export const SwitchGroup = <T,>({ options, onSelect, value }: Props<T>) => {
  return (
    <Container>
      {options.map((option) => (
        <SwitchButton
          $isSelected={value === option.value}
          key={String(option.value)}
          onClick={() => onSelect(option.value)}
        >
          {option.label}
        </SwitchButton>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  background-color: ${colors.grey.light};
  border-radius: 9999px;
  padding: 4px;
  gap: 4px;
`;

const SwitchButton = styled.button<{ $isSelected: boolean }>`
  padding: 4px 8px;
  border-radius: 9999px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  background-color: ${(props) => (props.$isSelected ? 'white' : 'transparent')};
  color: ${(props) => (props.$isSelected ? colors.black.full : colors.grey.default)};
  box-shadow: ${(props) => (props.$isSelected ? '0 1px 3px rgba(0,0,0,0.1)' : 'none')};

  &:hover {
    color: ${(props) => (props.$isSelected ? colors.black.full : colors.grey.dark)};
  }
`;
