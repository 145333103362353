import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import type { ArticleBody } from '@news/lib';

import { Typography } from 'components/Typography';
import { Chevron } from 'components/icons';
import { renderNode } from 'lib/bodyRenderer';
import { SECONDARY_TAB_MENU_HEIGHT_DESKTOP, TOPBAR_HEIGHT_DESKTOP } from 'styles/theme';
import { PREVENT_COLLAPSE_CLASS, isSelectingText } from 'views/feed/helpers';

interface Props {
  data: ArticleBody<'FactBoxData'>;
}

export const FactBox = ({ data: { body, title, data } }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  const content = useMemo(() => {
    if (!body?.children?.length) {
      return null;
    }
    return renderNode({
      node: body,
      options: {
        data,
      },
    });
  }, [body, data]);

  const toggleViewMore: React.MouseEventHandler = useCallback(
    (event) => {
      if (!isExpandable || isSelectingText()) {
        return;
      }

      event.stopPropagation();
      setIsExpanded(!isExpanded);
      if (isExpanded) {
        containerRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [isExpandable, isExpanded]
  );

  const handleWindowOrContentResize = useCallback(() => {
    const bodyHeight = bodyRef.current?.clientHeight ?? 0;
    const compare = window.innerHeight * 0.2;
    setIsExpandable(bodyHeight > compare);
  }, []);

  useEffect(() => {
    const debounced = debounce(handleWindowOrContentResize, 100);
    debounced();

    window.addEventListener('resize', debounced);
    return () => {
      window.removeEventListener('resize', debounced);
    };
  }, [content, handleWindowOrContentResize]);

  if (!content) return null;

  return (
    <FactBoxContainer
      $isExpandable={isExpandable}
      onClick={toggleViewMore}
      className={PREVENT_COLLAPSE_CLASS}
      ref={containerRef}
    >
      {!!title && <FactBoxTitle variant="title2">{title}</FactBoxTitle>}

      <FactBoxBody $isExpanded={!isExpandable || isExpanded} aria-expanded={isExpandable ? isExpanded : undefined}>
        <div ref={bodyRef}>{content}</div>
      </FactBoxBody>

      {isExpandable && (
        <ExpandCollapseButton $isExpanded={isExpanded}>
          <Typography variant="CTA">Visa {isExpanded ? 'mindre' : 'mer'}</Typography>
          <Chevron size={20} direction={isExpanded ? 'up' : 'down'} />
        </ExpandCollapseButton>
      )}
    </FactBoxContainer>
  );
};

const FactBoxContainer = styled.div<{ $isExpandable: boolean }>(
  ({ $isExpandable }) => `
  position: relative;
  background-color: ${colors.black[5]};
  margin-top: ${sizeUnits[16]};
  margin-bottom: ${sizeUnits[8]};
  scroll-behavior: smooth;
  ${$isExpandable && 'cursor: pointer'};
  scroll-margin-top: calc(${TOPBAR_HEIGHT_DESKTOP}px + ${SECONDARY_TAB_MENU_HEIGHT_DESKTOP}px + ${sizeUnits[16]});

  border-bottom: 2px solid ${colors.black[10]};
`
);

const ExpandCollapseButton = styled.button<{ $isExpanded: boolean }>`
  padding-top: ${({ $isExpanded }) => ($isExpanded ? sizeUnits[16] : 0)};
  padding-bottom: ${sizeUnits[16]};
  width: 100%;
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
`;

const FactBoxTitle = styled(Typography)`
  padding: ${sizeUnits[16]};
`;

const fade = css`
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(180deg, transparent 0, ${colors.gray.fade} 100%);
  }
`;

const FactBoxBody = styled.div<{ $isExpanded: boolean }>`
  ${({ $isExpanded }) => !$isExpanded && `max-height: 20vh`};

  ${(props) => !props.$isExpanded && fade}

  min-height: 140px;
  overflow: hidden;
  position: relative;
  padding: ${sizeUnits[8]} ${sizeUnits[16]} 0 ${sizeUnits[16]};

  ul {
    padding-left: ${sizeUnits[28]};
  }

  ol,
  ul,
  p {
    margin-bottom: ${sizeUnits[4]};
    margin-top: 0;
  }

  ul:empty,
  li:empty,
  p:empty,
  ol:empty {
    display: none;
  }
`;
