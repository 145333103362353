import _debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';

type WindowSize = {
  width?: number;
  height?: number;
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  const getSize = () => {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };

  useEffect(() => {
    setWindowSize(getSize());
    const handleResize = _debounce(() => {
      setWindowSize(getSize());
    }, 100);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export { useWindowSize };
