import styled from 'styled-components';

import { Ratio } from '@news/gql';

import { Byline } from 'components/Byline';
import { LazyImage } from 'components/LazyImage';
import { theme } from 'styles/theme';

type Props = {
  image: string;
  byline?: string;
  overlay?: React.ReactNode;
  className?: string;
};

export const FeaturedImage = ({ image, byline, overlay, className }: Props) => {
  return (
    <>
      <ImageWrapper className={className}>
        <LazyImage src={image} contain={false} ratio={Ratio.Ratio_16x9}>
          {overlay}
        </LazyImage>
      </ImageWrapper>
      {byline && (
        <BylineWrapper>
          <Byline text={byline} />
        </BylineWrapper>
      )}
    </>
  );
};

const BylineWrapper = styled.div`
  padding-left: 12px;
  padding-top: 1.6px;
  ${theme.mq.tablet} {
    padding-left: 16px;
  }
`;

const ImageWrapper = styled('div')`
  position: relative;
  width: 100%;
`;
